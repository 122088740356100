import { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import DetalleTrabajo from "../../components/Procesos/DetalleTrabajo/DetalleTrabajo";
import TablaTareas from "../../components/Procesos/TablaTareas.js/TablaTareas";
import Loading from "../../components/UI/Loading/Loading";
import classes from "./DetalleProceso.css";
import * as actions from "../../store/actions/index";

const DetalleProceso = ({
  procesoId,
  idSucursal,
  idSistema,
  idComproba,
  nroReferencia,
  cerrado,
  onLoadDetalleProceso,
  onLoadProcesosEnCurso,
  detalleProceso,
  loading,
  onPostCierreTarea,
  onUpdateCierreTarea,
  onShowErrorModal,
  cierreTareaSuccess,
  onClearCierreTareaSuccess,
  onDescargarArchivo,
  onPostChapasTarea, 
  postChapasTareaSuccess,
  onClearPostChapasTareaSuccess
}) => {
  useEffect(() => {
    onLoadDetalleProceso(
      procesoId,
      idSucursal,
      idSistema,
      idComproba,
      nroReferencia,
      cerrado
    );
  }, []);

  useEffect(() => {
    if (cierreTareaSuccess === true) {
      onLoadDetalleProceso(
        procesoId,
        idSucursal,
        idSistema,
        idComproba,
        nroReferencia,
        cerrado
      );
      onLoadProcesosEnCurso();
      onClearCierreTareaSuccess();
    }
  }, [cierreTareaSuccess]);

  useEffect(() => {
    if(postChapasTareaSuccess === true) {
      onClearPostChapasTareaSuccess();
    }
  }, [postChapasTareaSuccess]);

  const infoChapas = () => {
    return {
      idSucursal: idSucursal,
      idSistema: idSistema,
      idComprobante: idComproba,
      nroReferencia: nroReferencia,
      renglon: detalleProceso.renglon ? detalleProceso.renglon.trim() : "",
      idArticulo: detalleProceso.idArticulo
        ? detalleProceso.idArticulo.trim()
        : "",
      numeroAlta: detalleProceso.nroAlta,
      ordenDeTrabajo: procesoId,
    };
  };

  return (
    <Fragment>
      {loading ? (
        <div className={classes.DetalleProceso}>
          <Loading />
        </div>
      ) : (
        <Fragment>
          {" "}
          <DetalleTrabajo 
            info={detalleProceso} 
            loading={loading} 
            idSucursal={idSucursal}
            idSistema={idSistema}
            idComproba={idComproba}
            nroReferencia={nroReferencia} 
            onDescargarArchivo={onDescargarArchivo}/>
          <TablaTareas
            trabajos={detalleProceso.procesosTrabajo}
            infoChapas={infoChapas()}
            espesor={detalleProceso.espesor}
            materialId={detalleProceso.idMaterial}
            postCierreTarea={onPostCierreTarea}
            onShowErrorModal={onShowErrorModal}
            updateCierreTarea={onUpdateCierreTarea}
            postChapasTarea={onPostChapasTarea}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    detalleProceso: state.procesos.detalleProceso.detalle,
    loading: state.procesos.detalleProceso.loading,
    estaCargado: state.procesos.detalleProceso.estaCargado,
    cierreTareaSuccess: state.procesos.cierreTarea.success,
    postChapasTareaSuccess: state.procesos.postChapasTarea.success,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadDetalleProceso: (
      procesoId,
      idSucursal,
      idSistema,
      idComproba,
      nroReferencia,
      cerrado
    ) =>
      dispatch(
        actions.loadDetalleProceso(
          procesoId,
          idSucursal,
          idSistema,
          idComproba,
          nroReferencia,
          cerrado
        )
      ),
    onLoadProcesosEnCurso: (
      maquina,
      cliente,
      material,
      fechaDesde,
      fechaHasta,
      ordenDeTrabajo,
      nroPrograma,
      espesor
    ) =>
      dispatch(
        actions.loadProcesosEnCurso(
          maquina,
          cliente,
          material,
          fechaDesde,
          fechaHasta,
          ordenDeTrabajo,
          nroPrograma,
          espesor
        )
      ),
    onPostCierreTarea: (chapas) => dispatch(actions.loadCierreTarea(chapas)),
    onPostChapasTarea: (chapas) => dispatch(actions.postChapasTarea(chapas)), 
    onUpdateCierreTarea: (chapas) =>
      dispatch(actions.loadUpdateCierreTarea(chapas)),
    onShowErrorModal: (show, title, body) =>
      dispatch(actions.showErrorModal(show, title, body)),
    onClearCierreTareaSuccess: () => dispatch(actions.clearCierreTarea()),
    onClearPostChapasTareaSuccess: () => dispatch(actions.clearPostChapasTarea()), 
    onDescargarArchivo: (idSucursal, idSistema, idComproba, nroReferencia, idArticulo, renglon, tipoArchivo) =>
      dispatch(actions.descargarArchivos(idSucursal, idSistema, idComproba, nroReferencia, idArticulo, renglon, tipoArchivo))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetalleProceso);
