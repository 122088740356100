import { Fragment, useRef, useState, useEffect } from "react";
import classes from "./CierreTarea.css";
import { isEven } from "../../../assets/shared/formatNumber";
import Table from "../../UI/Table/Table";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import Button from "../../UI/Button/Button";
import moment from "moment";
import ErrorGrabacion from "../ErrorGrabacion/ErrorGrabacion";
import Loading from "../../UI/Loading/Loading";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const CierreTarea = ({
  infoChapas,
  idTarea,
  espesor,
  idMaterial,
  editaChapa,
  onClickGrabarHandler,
  errorGrabacion,
  onShowErrorModal,
  loading,
  chapas,
  onLoadChapas,
  onLoadChapasTarea,
  loadingChapasTarea,
  consulta,
  chapasTarea,
  onClearChapasTarea,
  onClearChapas,
  successCierreTarea,
  onHideModal,
  onClickModificarHandler,
  modificacion,
  trabajosSorted,
  puedeEscribir,
  onClickPostChapasHandler,
  successPostChapas,
}) => {
  const [auxChapas, setAuxChapas] = useState([]);
  const [fechaCierre, setFechaCierre] = useState();

  useEffect(() => {
    onLoadChapas();
      onLoadChapasTarea(
        infoChapas.idSucursal,
        infoChapas.idSistema,
        infoChapas.idComprobante,
        infoChapas.nroReferencia,
        infoChapas.idArticulo,
        infoChapas.renglon,
        idTarea,
        infoChapas.ordenDeTrabajo
      );

    return () => {
      onClearChapasTarea();
      onClearChapas();
      setAuxChapas([]);
    };
  }, []);

  useEffect(() => {
    if (chapasTarea !== undefined) {
      if (chapasTarea["fechaFinalizacion"] !== undefined && chapasTarea["fechaFinalizacion"] !== '1899-12-30T00:00:00')
        setFechaCierre(chapasTarea["fechaFinalizacion"]);
      setAuxChapas(chapasTarea["chapas"]);
    }
  }, [chapasTarea]);

  useEffect(() => {
    if (errorGrabacion)
      onShowErrorModal(
        true,
        "ERROR",
        <ErrorGrabacion error={errorGrabacion} />
      );
  }, [errorGrabacion]);

  useEffect(() => {
    if (successCierreTarea || successPostChapas) {
      onClearChapasTarea();
      onClearChapas();
      setAuxChapas([]);
      onHideModal();
    }
  }, [successCierreTarea, successPostChapas]);

  const isChapasOrDateEmpty = () =>{
    if (editaChapa) {
      const resultadoComparacion =
        fechaCierre === undefined ||
        isChapasEmpty()

      return resultadoComparacion;
    }
    return fechaCierre === undefined;
  }

  const isChapasEmpty = () => {
    if (editaChapa) {
      const resultadoComparacion =
        auxChapas === undefined ||
        auxChapas.length === 0 ||
        (auxChapas.length !== 0 &&
          auxChapas.some(
            (chapa) => chapa["idChapa"] === "" || chapa["cantidad"] === 0
          ));

      return resultadoComparacion;
    }
    return false;
  };

  const actualizarChapaYBuscarCertificado = (newData, oldData) => {
    const dataUpdate = [...auxChapas];
    const index = oldData.tableData.id;

    dataUpdate[index] = newData;
    const existeChapa = chapas.find(
      (chapa) => chapa["idChapa"].trim() === dataUpdate[index]["idChapa"].trim()
    );

    if (existeChapa !== undefined) {
      const certificado = existeChapa["certificado"];
      const generico = existeChapa["generico"];
      dataUpdate[index]["certificado"] = certificado;
      dataUpdate[index]["generico"] = generico;
    }

    setAuxChapas([...dataUpdate]);
  };

  const addChapa = () => {
    if (auxChapas !== undefined)
      setAuxChapas([
        ...auxChapas,
        { idChapa: "", cantidadChapas: 0, certificado: "", generico: false },
      ]);
    else
      setAuxChapas([
        { idChapa: "", cantidadChapas: 0, certificado: "", generico: false },
      ]);
  };

  const completarInformacionChapas = () => {
    const trabajoAnterior = trabajosSorted
      .map((a) => a.fechaFinalizacion)
      .slice(trabajosSorted.length - 2, trabajosSorted - 1)[0];
    return {
      ...infoChapas,
      idProceso: idTarea,
      idProcesoCerrado: idTarea,
      fechaFinalizacion: fechaCierre,
      editaChapa: editaChapa,
      fechaFinalizacionTrabajoAnterior:
        trabajoAnterior !== undefined
          ? moment(trabajoAnterior)
          : moment("1899-12-30T00:00:00"),
      Chapas:
        auxChapas &&
        auxChapas.map((chapa) => {
          return {
            espesor: espesor,
            material_tipo: idMaterial.trim(),
            idChapa: chapa["idChapa"].trim(),
            cantidadChapas: chapa["cantidadChapas"],
            generico: chapa["generico"],
          };
        }),
    };
  };

  const isColumnEditable = () => {
    if (consulta && !modificacion && !puedeEscribir) return "never";
    else return "always";
  };

  const columns = [
    {
      title: `Código chapa`,
      field: "idChapa",
      align: "left",
      editable: isColumnEditable(),
      sorting: false,
    },
    {
      title: "Certificado",
      field: "certificado",
      editable: "never",
      align: "left",
    },
    {
      title: "Cantidad",
      field: "cantidadChapas",
      editable: isColumnEditable(),
      align: "left",
      type: "numeric",
    },
  ];

  const isRowEditable = () => {
    if (consulta && !modificacion) return null;
    else {
      return {
        onRowAdd: (nuevaChapa) =>
          new Promise((resolve, reject) => {
            setChapas({ ...auxChapas, nuevaChapa });
            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            actualizarChapaYBuscarCertificado(newData, oldData);
            resolve();
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            const dataDelete = [...auxChapas];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            setAuxChapas([...dataDelete]);
            resolve();
          }),
      };
    }
  };

  const tableRef = useRef();

  const GrabacionOModificacion = () => {
    if (!consulta) {
      return (
        <div className={classes.Grabar}>
          <Button
            disabled={isChapasOrDateEmpty()}
            onClick={() => {
              onClickGrabarHandler(completarInformacionChapas());
            }}
          >
            Grabar
          </Button>
        </div>
      );
    } else {
      if (modificacion) {
        return (
          <div className={classes.Grabar}>
            <Button
              disabled={isChapasEmpty()}
              onClick={() => {
                onClickModificarHandler(completarInformacionChapas());
              }}
            >
              Grabar
            </Button>
          </div>
        );
      } else return null;
    }
  };

  return (
    <Fragment>
      {loading || loadingChapasTarea ? (
        <div className={classes.classes}>
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className={classes.FechaCierre}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                disabled={(consulta && !modificacion) || !puedeEscribir}
                disableFuture={true}
                variant="inline"
                format="dd/MM/yyyy"
                margin="none"
                id="date-picker-inline"
                label="Fecha cierre"
                value={
                  fechaCierre && fechaCierre !== undefined ? fechaCierre : null
                }
                defaultValue={null}
                onChange={(value) => setFechaCierre(value)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            {(!consulta && editaChapa) ||
            (consulta && modificacion && editaChapa) ? (
              <Button onClick={addChapa} disabled={!puedeEscribir}>
                Agregar
              </Button>
            ) : null}
          </div>
          {editaChapa ? (
            <div className={classes.Root}>
              <div className={classes.Table}>
                <Table
                  title=""
                  className={classes.TableBody}
                  loading={false}
                  columns={columns}
                  data={auxChapas}
                  noBoxShadow
                  customOptions={{
                    thirdSortClick: false,
                    sorting: true,
                    grouping: false,
                    draggable: false,
                    search: false,
                    paging: false,
                    toolbar: false,
                    showTitle: false,
                    maxBodyHeight: 325,
                    rowStyle: (rowData, index) => ({
                      backgroundColor: !isEven(index) && "#f2f2f2",
                    }),
                  }}
                  editable={isRowEditable()}
                />
              </div>
            </div>
          ) : null}
          {GrabacionOModificacion()}
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errorGrabacion: state.procesos.cierreTarea.error,
    chapas: state.procesos.chapas.chapas,
    loadingChapas: state.procesos.chapas.loading,
    loadingChapasTarea: state.procesos.chapasTarea.loading,
    chapasTarea: state.procesos.chapasTarea.chapasTarea,
    successCierreTarea: state.procesos.cierreTarea.success,
    successPostChapas: state.procesos.postChapasTarea.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadChapas: () => dispatch(actions.loadChapas()),
    onLoadChapasTarea: (
      idSucursal,
      idSistema,
      idComproba,
      nroReferencia,
      idArticulo,
      renglon,
      idTarea,
      ordenDeTrabajo
    ) =>
      dispatch(
        actions.loadChapasTarea(
          idSucursal,
          idSistema,
          idComproba,
          nroReferencia,
          idArticulo,
          renglon,
          idTarea,
          ordenDeTrabajo
        )
      ),
    onClearChapasTarea: () => dispatch(actions.clearChapasTarea()),
    onClearChapas: () => dispatch(actions.clearChapas()),
    onHideModal: () => dispatch(actions.hiddenGlobalModal()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CierreTarea);
