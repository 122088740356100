import TablaProcesos from "../../components/Procesos/TablaProcesos";
import Tabs from "../../components/UI/Tabs/Tabs";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { Fragment, useCallback, useEffect, useState } from "react";
import Loading from "../../components/UI/Loading/Loading";
import classes from "./Procesos.css";
import moment from "moment";
const Procesos = (props) => {
  const [filtrosEnCurso, setFiltrosEnCurso] = useState({
    fechaDesde: undefined,
    fechaHasta: undefined,
    maquina: undefined,
    cliente: undefined,
    proceso: undefined, 
    tipo: undefined,
    material: undefined,
    ordenDeTrabajo: undefined,
    nroDePrograma: undefined,
    espesor: undefined,
    estado: undefined, 
  });

  const [filtrosTerminados, setFiltrosTerminados] = useState({
    fechaDesde: undefined,
    fechaHasta: undefined,
    maquina: undefined,
    cliente: undefined,
    proceso: undefined, 
    tipo: undefined,
    material: undefined,
    ordenDeTrabajo: undefined,
    nroDePrograma: undefined,
    espesor: undefined,
    estado: undefined
  });

  const [procesosEnCurso, setProcesosEnCurso] = useState([]);
  const [procesosTerminados, setProcesosTerminados] = useState([]);

  const handleOnChangeFiltrosEnCurso = useCallback(
    (minLength, property, value) => {
      const copiaFiltros = { ...filtrosEnCurso };
      copiaFiltros[property] = value;
      setFiltrosEnCurso(copiaFiltros);
    },
    [filtrosEnCurso]
  );

  const handleOnChangeFiltrosTerminados = useCallback(
    (minLength, property, value) => {
      const copiaFiltros = { ...filtrosTerminados };
      copiaFiltros[property] = value;
      setFiltrosTerminados(copiaFiltros);
    },
    [filtrosTerminados]
  );

  useEffect(() => {
    setProcesosEnCurso(props.procesosEnCurso);
  }, [props.procesosEnCurso]);

  useEffect(() => {
    setProcesosTerminados(props.procesosTerminados);
  }, [props.procesosTerminados]);

  useEffect(() => {
    props.onLoadMaquinas();
    props.onLoadClientes();
    props.onLoadMateriales();
    props.onLoadProcesos(); 
    props.onLoadProcesosEnCurso();
    props.onLoadProcesosTerminados(true);
  }, []);

  useEffect(() => {
    const procesos = props.procesosEnCurso;
    if (procesos !== undefined && procesos.length > 0) {
      const dataFiltrada = procesos.filter(
        (dt) =>
          (filtrosEnCurso.maquina === undefined ||
          (filtrosEnCurso.maquina && filtrosEnCurso.maquina.id === "TD")
            ? true
            : dt.maquina.nombre === filtrosEnCurso.maquina.nombre) &&
          (filtrosEnCurso.proceso === undefined ||
          (filtrosEnCurso.proceso && filtrosEnCurso.proceso.id === "TD")
            ? true
            : dt.proceso === filtrosEnCurso.proceso.nombre) && 
          (filtrosEnCurso.cliente === undefined ||
          (filtrosEnCurso.cliente && filtrosEnCurso.cliente.id === "TD")
            ? true
            : dt.cliente.razonSocial === filtrosEnCurso.cliente.nombre) &&
          (filtrosEnCurso.tipo === undefined ||
          (filtrosEnCurso.tipo && filtrosEnCurso.tipo.id === "TD")
            ? true
            : filtrosEnCurso.tipo.id === "Propio"
            ? dt.propio === true
            : dt.propio === false) &&
          (filtrosEnCurso.material === "" ||
          filtrosEnCurso.material === undefined
            ? true
            : dt.material.descripcion.includes(filtrosEnCurso.material)) &&
          (filtrosEnCurso.ordenDeTrabajo === "" ||
          filtrosEnCurso.ordenDeTrabajo === undefined
            ? true
            : dt.ordenDeTrabajo.includes(filtrosEnCurso.ordenDeTrabajo)) &&
          (filtrosEnCurso.nroDePrograma === "" ||
          filtrosEnCurso.nroDePrograma === undefined
            ? true
            : dt.nroPrograma === filtrosEnCurso.nroDePrograma) &&
          (filtrosEnCurso.espesor === 0 || filtrosEnCurso.espesor === undefined
            ? true
            : dt.espesor === filtrosEnCurso.espesor) &&
          (filtrosEnCurso.estado === undefined || 
          (filtrosEnCurso.estado && filtrosEnCurso.estado.id === undefined)
            ? true  
            : dt.pausado === filtrosEnCurso.estado.id) && 
          (filtrosEnCurso.fechaDesde === undefined ||
          filtrosEnCurso.fechaDesde === null
            ? true
            : moment(dt.fechaEntrega).isSameOrAfter(
                moment(filtrosEnCurso.fechaDesde),
                "day"
              )) &&
          (filtrosEnCurso.fechaHasta === undefined ||
          filtrosEnCurso.fechaHasta === null
            ? true
            : moment(dt.fechaEntrega).isSameOrBefore(
                moment(filtrosEnCurso.fechaHasta),
                "day"
              ))
      );
      setProcesosEnCurso(dataFiltrada);
    }
  }, [filtrosEnCurso, props.procesosEnCurso]);

  useEffect(() => {
    const procesos = props.procesosTerminados;
    if (procesos !== undefined && procesos.length > 0) {
      const dataFiltrada = procesos.filter(
        (dt) =>
          (filtrosTerminados.maquina === undefined ||
          (filtrosTerminados.maquina && filtrosTerminados.maquina.id === "TD")
            ? true
            : dt.maquina.nombre === filtrosTerminados.maquina.nombre) &&
          (filtrosTerminados.proceso === undefined ||
          (filtrosTerminados.proceso && filtrosTerminados.proceso.id === "TD")
            ? true
            : dt.proceso === filtrosTerminados.proceso.nombre) &&
          (filtrosTerminados.cliente === undefined ||
          (filtrosTerminados.cliente && filtrosTerminados.cliente.id === "TD")
            ? true
            : dt.cliente.razonSocial === filtrosTerminados.cliente.nombre) &&
          (filtrosTerminados.tipo === undefined ||
          (filtrosTerminados.tipo && filtrosTerminados.tipo.id === "TD")
            ? true
            : filtrosTerminados.tipo.id === "Propio"
            ? dt.propio === true
            : dt.propio === false) &&
          (filtrosTerminados.material === "" ||
          filtrosTerminados.material === undefined
            ? true
            : dt.material.descripcion.includes(filtrosTerminados.material)) &&
          (filtrosTerminados.ordenDeTrabajo === "" ||
          filtrosTerminados.ordenDeTrabajo === undefined
            ? true
            : dt.ordenDeTrabajo.includes(filtrosTerminados.ordenDeTrabajo)) &&
          (filtrosTerminados.nroDePrograma === "" ||
          filtrosTerminados.nroDePrograma === undefined
            ? true
            : dt.nroPrograma === filtrosTerminados.nroDePrograma) &&
          (filtrosTerminados.espesor === 0 ||
          filtrosTerminados.espesor === undefined
            ? true
            : dt.espesor === filtrosTerminados.espesor) &&
            (filtrosTerminados.estado === undefined || 
            (filtrosTerminados.estado && filtrosTerminados.estado.id === undefined)
              ? true  
              : dt.pausado === filtrosTerminados.estado.id) &&
          (filtrosTerminados.fechaDesde === undefined ||
          filtrosTerminados.fechaDesde === null
            ? true
            : moment(dt.fechaEntrega).isSameOrAfter(
                moment(filtrosTerminados.fechaDesde),
                "day"
              )) &&
          (filtrosTerminados.fechaHasta === undefined ||
          filtrosTerminados.fechaHasta === null
            ? true
            : moment(dt.fechaEntrega).isSameOrBefore(
                moment(filtrosTerminados.fechaHasta),
                "day"
              ))
      );
      setProcesosTerminados(dataFiltrada);
    }
  }, [filtrosTerminados, props.procesosTerminados]);

  return (
    <Fragment>
      {props.loadingMaquinas ||
      props.loadingClientes ||
      props.loadingMateriales ||
      props.loadingProcesos || 
      props.loadingEnCurso ||
      props.loadingTerminados ? (
        <div className={classes.Procesos}>
          <Loading />
        </div>
      ) : (
        <Tabs
          tabs={["Procesos en curso", "Procesos terminados"]}
          views={[
            <TablaProcesos
              maquinas={props.maquinas}
              clientes={props.clientes}
              tipo={props.tipo}
              onChangeFiltros={handleOnChangeFiltrosEnCurso}
              procesos={props.procesos}
              nombre={"En curso"}
              filtros={filtrosEnCurso}
              key={"En curso"}
              cerrado={false}
              trabajos={procesosEnCurso}
            />,
            <TablaProcesos
              maquinas={props.maquinas}
              clientes={props.clientes}
              tipo={props.tipo}
              onChangeFiltros={handleOnChangeFiltrosTerminados}
              procesos={props.procesos}
              nombre={"Terminados"}
              filtros={filtrosTerminados}
              key={"Terminados"}
              cerrado={true}
              trabajos={procesosTerminados}
            />,
          ]}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    procesosEnCurso: state.procesos.procesos.enCurso,
    procesosTerminados: state.procesos.procesos.terminados,
    procesos: state.procesos.procesosDisponibles.procesos, 
    maquinas: state.procesos.maquinas.maquinas,
    clientes: state.clientes.clientes.clientes,
    tipo: state.procesos.materiales.materiales,
    loadingMaquinas: state.procesos.maquinas.loading,
    loadingClientes: state.clientes.clientes.loading,
    loadingProcesos: state.procesos.procesosDisponibles.loading, 
    loadingMateriales: state.procesos.materiales.loading,
    loadingEnCurso: state.procesos.procesos.loadingEnCurso,
    loadingTerminados: state.procesos.procesos.loadingTerminados,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadMaquinas: () => dispatch(actions.loadMaquinas()),
    onLoadClientes: () => dispatch(actions.loadClientes()),
    onLoadMateriales: () => dispatch(actions.loadMateriales()),
    onLoadProcesos: () => dispatch(actions.loadProcesos()),
    onLoadProcesosEnCurso: (
      maquina,
      cliente,
      material,
      fechaDesde,
      fechaHasta,
      ordenDeTrabajo,
      nroPrograma,
      espesor
    ) =>
      dispatch(
        actions.loadProcesosEnCurso(
          maquina,
          cliente,
          material,
          fechaDesde,
          fechaHasta,
          ordenDeTrabajo,
          nroPrograma,
          espesor
        )
      ),

    onLoadProcesosTerminados: (
      finalizado,
      maquinaId,
      clienteId,
      materialId,
      fechaDesde,
      fechaHasta,
      ordenDeTrabajo,
      nroPrograma,
      espesor
    ) =>
      dispatch(
        actions.loadProcesosTerminados(
          maquinaId,
          clienteId,
          materialId,
          fechaDesde,
          fechaHasta,
          ordenDeTrabajo,
          nroPrograma,
          espesor,
          finalizado
        )
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Procesos);
